import {
    Button,
    Grid,
    Typography
}                                from "@mui/material";
import {
    useEffect,
    useRef,
    useState
}                                from "react";
import {
    AddWell,
    AddWellCommand,
    Contact,
    DeactivateContactCommand,
    IGStyle,
    UpdateContact,
    Well,
    WellDetail
} from "../../constants";
import {useOnttrekkingenContext} from "../../context/OnttrekkingenContextProvider";
import {submitObject}   from "../../util";

const WellBlock = () =>
{
    const {
        companyDetails,selectedEnergyRecord,
        selectedCompanyRecord,
        setFormData,setDetailRecord,setDetailStatus,
        setModalVisible,
        setModalAction, setActivePage, setSubPage, setSelectedBronFilterRecord,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText, setBronDetails
    } = useOnttrekkingenContext()

    //<editor-fold desc="Changeable Variables">

    const Title= "Bronnen"
    const RMName= "ONT_WellRM"

    const MasterRefresh = ""

    const AddAction = AddWell

    const AddButton = "Bron Toevoegen"
    const AddEnabled = true

    const DSLFilter = selectedEnergyRecord
    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            console.log(event.detail.record)
            setFormData(event.detail.record)
            setSelectedBronFilterRecord(`wellId =="` + event.detail.record?.id + "\"")
            setBronDetails(event.detail.record)
            setActivePage(3)
            setSubPage(0)
        })
    }, [])

    //</editor-fold>

    //<editor-fold desc="Master - Does not need changing">
    const RMRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case MasterRefresh:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddObject()
    {
        setFormData(RMRef.current.data)
        setModalVisible(true)
        setModalAction(AddAction)

    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" ref={RMRef} class={"ig-columns"} style={IGStyle} resource-master-id={RMName} dsl-filter={DSLFilter}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"}  onClick={AddObject}>{AddButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
    //</editor-fold>
}

export default WellBlock