import {
    Button,
    Grid,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useRef
}                               from "react";
import {
    LegalEntity,
    IGStyle,
    AddSystem,
    EnergySystem,
} from "../../constants";
import {useOnttrekkingenContext}         from "../../context/OnttrekkingenContextProvider";
import {getCompanyRecordSystem} from "../../util";

const SearchableEnergySystemBlock = () =>
{
    const {
        setActivePage, setEnergyDetails,setFormData,
        setCompanyDetails, setSubPage,setModalAction,
        setSelectedCompanyRecord,setModalVisible,setSelectedEnergyRecordMeasurement,
        refreshResource, setRefreshResource, setSelectedEnergyRecord
    } = useOnttrekkingenContext()


    //<editor-fold desc="Changeable Variables">

    const Title= "Systemen"
    const RMName= "ONT_SearchableSystemRM"

    const MasterRefresh = EnergySystem

    const AddAction = AddSystem

    const AddButton = "Systeem Toevoegen"
    const AddEnabled = false

    const DSLFilter = ""

    useEffect(() =>
    {

        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setEnergyDetails(event.detail.record)
            setActivePage(2)
            setSubPage(0)
            setSelectedEnergyRecord(`systemId =="` + event.detail.record?.id + "\"")
            setSelectedEnergyRecordMeasurement(`systemMeasurements.systemId =="` + event.detail.record?.id + "\"")

            getCompanyRecordSystem(event.detail.record.aggregateInstanceId).then(async (data: any) =>
                {
                    const Data = await data.json()
                    setCompanyDetails(Data)
                    setSelectedCompanyRecord(`aggregateInstanceId =="` + event.detail.record.aggregateInstanceId + "\"")
                }
            )
        });
        setTimeout(() =>
        {
            const box: any = document.getElementById("aggregateState0")
            if (box != null)
            {
                box.click()
                box.checked=true
                var event = new Event('change', { bubbles: true });
                box.dispatchEvent(event);
            }
        }, 1000)

    }, [])
    //</editor-fold>


    //<editor-fold desc="Searchable Master - Does not need changing">
    const RMRef = useRef<any>()


    useEffect(() =>
    {
        switch (refreshResource)
        {
            case MasterRefresh:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddObject()
    {
        setFormData(undefined)
        setCompanyDetails(undefined)

        setModalVisible(true)
        setModalAction(AddAction)
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" style={IGStyle} ref={RMRef} class={"ig-columns hide-filter-searchable-master"} searchable-resource-master-id={RMName}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"} onClick={AddObject}>{AddButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
    //</editor-fold>
}

export default SearchableEnergySystemBlock