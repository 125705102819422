import {
    AuthToken,
    baseUrl,
    HeffingsTariefUrl,
    LegalEntityUrl,
    RegisterLegalEntityCommand,
    REST
} from "./constants";

//<editor-fold desc="Retrieval">
export function getCompanyRecordSystem(Identifier: any)
{
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        }
    };

    return fetch(baseUrl + REST + LegalEntityUrl  + "/" + Identifier, requestOptions).then()

}

//</editor-fold>

//<editor-fold desc="Update">

export function submitObject(details: any, AggregateId: string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + LegalEntityUrl + "/" + AggregateId + Command, requestOptions)
        .then(response => response)
}
export function submitUpdateHeffingsObject(details: any, AggregateId: string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + HeffingsTariefUrl + "/" + AggregateId + Command, requestOptions)
        .then(response => response)
}
export function submitNewHeffingsObject(details: any, AggregateId: string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + HeffingsTariefUrl + Command, requestOptions)
        .then(response => response)
}
//</editor-fold>

//<editor-fold desc="Company">

export function submitNewCompany(companyDetails: any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(companyDetails)
    };
    return fetch(baseUrl + REST + LegalEntityUrl + RegisterLegalEntityCommand, requestOptions)
        .then(response => response)
}

//</editor-fold>
