import SearchableLegalEntityBlock  from "../components/blocks/SearchableLegalEntityBlock";
import SearchableEnergySystemBlock from "../components/blocks/SearchableSystem";

const LegalEntityOverview = () => {

    return (<>
        <SearchableLegalEntityBlock/>
        <SearchableEnergySystemBlock/>
    </>)
}
export default LegalEntityOverview