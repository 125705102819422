import {
    Button,
    Typography
}                       from "@mui/material";
import {useOnttrekkingenContext} from "../context/OnttrekkingenContextProvider";

const DeleteForm = () =>
{
    const {setModalVisible, deleteCallback} = useOnttrekkingenContext()

    function onClickSubmit()
    {
        deleteCallback.delete()
        setModalVisible(false)
    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            Weet je zeker dat je dit item wil verwijderen?
        </Typography>
        <Button variant="contained" onClick={onClickSubmit}>Verwijderen</Button>
        <Button variant="contained" onClick={() => setModalVisible(false)}>Annuleren</Button>
    </>)
}
export default DeleteForm