import {
    Button,
    Typography
}                                from "@mui/material";
import {
    useEffect,
    useRef
}                                from "react";
import {
    AddHeffingsTariefCommand,
    HeffingsTarief,
    HeffingsTariefDetail,
    UpdateHeffingsTariefCommand
}                                from "../constants";
import {useOnttrekkingenContext} from "../context/OnttrekkingenContextProvider";
import {
    submitUpdateHeffingsObject,
    submitObject,
    submitNewHeffingsObject
} from "../util";

const HeffingsTariefForm = () =>
{
    const {
        companyDetails, energyDetails, setModalVisible, formData, setRefreshResource,
        setAlertText, setAlertVisible
    } = useOnttrekkingenContext()

    //<editor-fold desc="Changeable Variables">
    const AddTitle = "Heffingstarief toevoegen"
    const EditTile = "Heffingstarief bewerken"

    const AddForm = "ONT_AddHeffingsTarief"
    const UpdateForm = "ONT_UpdateHeffingsTarief"

    const AggregateDetails = companyDetails
    const ExtraFormData = formData
    const SubmitButton = "Submit"

    const DetailRefresh = HeffingsTariefDetail
    const MasterRefresh = HeffingsTarief

    const AddCommand = AddHeffingsTariefCommand
    const UpdateCommand = UpdateHeffingsTariefCommand

    useEffect(() =>
    {
        if (FormRef.current !== undefined && ExtraFormData !== undefined)
        {
            FormRef.current.data = {...ExtraFormData}
        }
    }, []);
    //</editor-fold>

    //<editor-fold desc="Form - Does not need changing">
    const FormRef = useRef<any>()

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitNewHeffingsObject(Data, AggregateDetails?.id, AddCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(MasterRefresh)

                }
                else
                {
                    submitUpdateHeffingsObject(Data, FormRef.current.data.id, UpdateCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(DetailRefresh)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {ExtraFormData === undefined
             ? AddTitle
             : EditTile}
        </Typography>
        {/*// @ts-ignore*/}

        {ExtraFormData === undefined
            // @ts-ignore
         ? <ig-form ref={FormRef} form-id={AddForm}/>
            // @ts-ignore
         : <ig-form ref={FormRef} form-id={UpdateForm}/>}
        <Button variant="contained" onClick={onClickSubmit}>{SubmitButton}</Button>
    </>)
    //</editor-fold>

}
export default HeffingsTariefForm