import {
    Button,
    Grid,
    Typography
}                        from "@mui/material";
import React, {
    useEffect,
    useRef
}                        from "react";
import {
    AddSystem,
    EnergySystem,
    IGStyle,
}                       from "../../constants";
import {useOnttrekkingenContext} from "../../context/OnttrekkingenContextProvider";

const EnergySystemBlock = () =>
{
    const { setModalVisible, setModalAction,setEnergyDetails,setActivePage,setSubPage,companyDetails
        , refreshResource, setRefreshResource,selectedCompanyRecord,setSelectedEnergyRecord,setFormData
    } = useOnttrekkingenContext()

    //<editor-fold desc="Changeable Variables">

    const Title= "Systemen"
    const RMName= "ONT_SystemRM"

    const MasterRefresh = EnergySystem

    const AddAction = AddSystem
    const AddButton = "Systeem Toevoegen"
    const AddEnabled = true

    const DSLFilter = selectedCompanyRecord

    //</editor-fold>

    //<editor-fold desc="Master - Does not need changing">
    const RMRef = useRef<any>()

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setEnergyDetails(event.detail.record)
            setActivePage(2)
            setSubPage(0)
            setSelectedEnergyRecord(`systemId =="` + event.detail.record?.id + "\"")

        });

    }, [])


    useEffect(() =>
    {
        switch (refreshResource)
        {
            case MasterRefresh:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddObject()
    {
        setFormData(RMRef.current.data)
        setModalVisible(true)
        setModalAction(AddAction)

    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" ref={RMRef} class={"ig-hide-1st-column"} style={IGStyle} resource-master-id={RMName} dsl-filter={DSLFilter}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"}  onClick={AddObject}>{AddButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
    //</editor-fold>
}

export default EnergySystemBlock