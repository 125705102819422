import CadastralBlock from "../components/blocks/CadastralBlock";
import SystemBlock    from "../components/blocks/SystemBlock";
import PermitBlock    from "../components/blocks/PermitBlock";
import WellBlock         from "../components/blocks/WellBlock";

const SysteemGegevensOverview = () => {

    return (<>
        <SystemBlock/>
        <CadastralBlock/>
        <WellBlock/>
        <PermitBlock/>
    </>)
}
export default SysteemGegevensOverview
