export const baseUrl : string = process.env.NODE_ENV === "production"
                                ? window.location.protocol + "//" + window.location.host
                                : "https://teec2-odzob-dev.informationgrid.io"
const productieToken = "yMbuGOoz+9yKvYhNi1OfbA=="
const accToken = "XA3T/IqaBrn9mo+rKsw/cw=="
const testToken = "iACU4d5q12mONw4+Ivwi1Q=="
export const AuthToken : string =accToken//"XA3T/IqaBrn9mo+rKsw/cw=="//
//XA3T/IqaBrn9mo+rKsw/cw==
export const REST : string = "/ig/rest"


export const IGStyle = {
    width:"100%"
}
export const Delete : string = "Delete"

//<editor-fold desc="Commands">

//<editor-fold desc="Company">
export const LegalEntity : string = "LegalEntity"
export const LegalEntityDetail : string = "LegalEntityDetail"
export const AddLegalEntity : string = "AddLegalEntity"
export const UpdateLegalEntity : string = "UpdateLegalEntity"

export const LegalEntityUrl : string = "/aggregate/ONT_LegalEntity"
export const RegisterLegalEntityCommand : string = "/command/RegisterLegalEntity"
export const UpdateLegalEntityCommand : string = "/command/UpdateLegalEntity"
export const DeactivateLegalEntityCommand : string = "/command/DeactivateLegalEntity"

//</editor-fold>

//<editor-fold desc="LegalEntityMeasurements">
export const LegalEntityMeasurements : string = "LegalEntityMeasurement"
export const LegalEntityMeasurementDetail : string = "LegalEntityMeasurementDetail"
export const AddLegalEntityMeasurement : string = "AddLegalEntityMeasurement"
export const UpdateLegalEntityMeasurement : string = "UpdateLegalEntityMeasurement"

export const LegalEntityUrlMeasurementsNew : string = "/aggregate/LegalEntityMeasurement"
export const LegalEntityMeasurementsUrl : string = "/aggregate/LegalEntityMeasurement/"
export const AddLegalEntityMeasurementCommand : string = "/command/RegisterLegalEntityMeasurement"
export const UpdateLegalEntityMeasurementCommand : string = "/command/UpdateLegalEntityMeasurement"
export const DeactivateLegalEntityMeasurementCommand : string = "/command/DeactivateLegalEntityMeasurement"

//</editor-fold>

//<editor-fold desc="Cadastral">
export const Cadastral : string =  "Cadastral"
export const CadastralDetail : string =  "CadastralDetail"


export const AddCadastralInfo : string = "AddCadastralInfo"
export const UpdateCadastralInfo : string = "UpdateCadastralInfoo"

export const AddCadastralInfoCommand : string = "/command/AddCadastralInfo"
export const UpdateCadastralInfoCommand : string = "/command/UpdateCadastralInfo"
export const DeactivateCadastralCommand : string = "/command/DeactivateCadastralInfo"

//</editor-fold>

//<editor-fold desc="HeffingsTarief">
export const HeffingsTarief : string =  "HeffingsTarief"
export const HeffingsTariefDetail : string =  "HeffingsTariefDetail"
export const HeffingsTariefUrl : string = "/aggregate/ONT_HeffingsTarief"

export const AddHeffingsTarief : string =  "AddHeffingsTarief"
export const UpdateHeffingsTarief : string =  "UpdateHeffingsTarief"
export const AddHeffingsTariefCommand : string =  "/command/Add"
export const UpdateHeffingsTariefCommand : string = "/command/Update"
export const DeactivateHeffingsTariefCommand : string = "/command/DeactivateHeffingsTarief"
//</editor-fold>

//<editor-fold desc="EnergySystem">
export const EnergySystem : string =  "EnergySystem"
export const EnergySystemDetail : string =  "EnergySystemDetail"

export const AddSystem : string =  "AddSystem"
export const UpdateEnergySystem : string =  "UpdateSystem"
export const UpdateEnergySystemStatus : string =  "UpdateSystemStatus"
export const UpdateEnergySystemStatusToezicht : string =  "UpdateSystemStatusToezicht"
export const UpdateChangeOwnership : string =  "UpdateChangeOwnership"

export const AddEnergySystemCommand : string =  "/command/AddSystem"
export const UpdateEnergySystemCommand : string = "/command/UpdateSystem"
export const UpdateEnergySystemStatusCommand : string = "/command/UpdateSystemStatus"
export const UpdateEnergySystemStatusToezichtCommand : string = "/command/UpdateSystemStatusToezicht"
export const UpdateChangeOwnershipCommand : string =  "/command/RequestOwnershipChange"

export const DeactivateEnergySystemCommand : string = "/command/DeactivateSystem"
//</editor-fold>

//<editor-fold desc="Well">
export const Well  : string = "Well"
export const WellDetail  : string = "WellDetail"
export const AddWell  : string = "AddWell"
export const UpdateWell  : string = "UpdateWell"
export const AddWellCommand : string = "/command/AddWell"
export const UpdateWellCommand : string = "/command/UpdateWell"
export const DeactivateWellCommand : string = "/command/DeactivateWell"

//</editor-fold>

//<editor-fold desc="Boorprofiel">
export const Boorprofiel : string = "Boorprofiel"
export const BoorprofielDetail : string = "BoorprofielDetail"

export const AddBoorprofiel : string = "AddBoorprofiel"
export const UpdateBoorprofiel : string = "UpdateBoorprofiel"
export const AddBoorprofielCommand : string = "/command/AddBoorprofiel"
export const UpdateBoorprofielCommand : string = "/command/UpdateBoorprofiel"
export const DeactivateBoorprofielCommand : string = "/command/DeactivateBoorprofiel"

//</editor-fold>

//<editor-fold desc="Permit">
export const Permit : string = "Permit"
export const PermitDetail : string = "PermitDetail"
export const AddPermit : string = "AddPermit"

export const UpdatePermit : string = "UpdatePermit"

export const AddPermitCommand : string = "/command/AddPermit"
export const UpdatePermitCommand : string = "/command/UpdatePermit"
export const DeactivatePermitCommand : string = "/command/DeactivatePermit"


//<editor-fold desc="Well">
export const PermitWell  : string = "PermitWell"
export const PermitWellDetail  : string = "PermitWellDetail"
export const AddPermitWell  : string = "AddPermitWell"
export const UpdatePermitWell  : string = "UpdatePermitWell"
export const AddPermitWellCommand : string = "/command/AddPermitWell"
export const UpdatePermitWellCommand : string = "/command/UpdatePermitWell"
export const DeactivatePermitWellCommand : string = "/command/DeactivatePermitWell"

//</editor-fold>
//</editor-fold>

//<editor-fold desc="Contact">
export const Contact: string = "Contact"
export const ContactDetail: string = "ContactDetail"
export const AddContact: string = "AddContact"
export const UpdateContact: string = "UpdateContact"

export const AddContactCommand : string = "/command/AddContact"
export const UpdateContactCommand : string = "/command/UpdateContact"
export const DeactivateContactCommand : string  ="/command/DeactivateContact"
//</editor-fold>

//<editor-fold desc="Controle">
export const Controle : string = "Controle"
export const ControleDetail : string = "ControleDetail"


export const AddControle : string = "AddControle"
export const UpdateControle : string = "UpdateControle"

export const AddControleCommand : string = "/command/AddControle"
export const UpdateControleCommand : string = "/command/UpdateControle"
export const DeactivateControleCommand : string = "/command/DeactivateControle"
//</editor-fold>

//<editor-fold desc="Note">
export const Note : string = "Note"
export const NoteDetail : string = "NoteDetail"

export const AddNote : string = "AddNote"
export const UpdateNote : string = "UpdateNote"
export const AddNoteCommand : string = "/command/AddNote"
export const UpdateNoteCommand : string = "/command/UpdateNote"
export const DeactivateNoteCommand : string = "/command/DeactivateNote"
//</editor-fold>

//</editor-fold>

