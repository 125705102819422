import LegalEntityBlock from "../components/blocks/LegalEntityBlock";
import ContactBlock                 from "../components/blocks/ContactBlock";
import FilteredEnergySystemBlock    from "../components/blocks/FilteredSystemBlock";
import LegalEntityMeasurementsBlock from "../components/blocks/LegalEntityMeasurementsBlock";

const LegalEntityDetailOverview = () => {

    return (<>
        <LegalEntityBlock/>
        <ContactBlock/>
        <FilteredEnergySystemBlock/>
    </>)
}
export default LegalEntityDetailOverview
